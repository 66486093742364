.App {
  text-align: center;
}

.container {
  height: 90vh; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer{
  margin-top: 1rem;
  padding: 1.5rem;
  background-color: rgb(252, 215, 96);
  position: fixed;
  bottom: 0;
  width: 100%;
  font-family: 'Zilla Slab', serif;
  text-align: center;
}

@media (max-width: 800px){
  .footer{
    margin-top: 1rem;
    background-color: rgb(252, 215, 96);
    position: fixed;
    bottom: 0;
    width: 100%;
    font-family: 'Zilla Slab', serif;
  }

}

.lottie{
  z-index: 0;
  height: 70vh;
}

@media (max-width: 800px){
  .lottie{
    height: 40vh;
  }
}

.footer-img{
  margin-top: 1%;
  height: 2vmin;
  align-items: center;
}

@media (max-width: 767px){
  .footer-img{
    margin-top: 0.5%;
    height: 4vmin;
  }
}

html {
  height: 100% ;
  background: rgba(252, 216, 96, 0.315);
}

.Home-logo {
  height: 20vmin;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a{
  padding-left: 10px;
}
.two-buttons{
  display: flex !important;
  padding-left: 10px;
  align-items: center;
  
  justify-content: center;
}

.social-button {
  z-index: 2;
  height: 5.5vmin;
  transition: 0.5s !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px){
  .social-button {
    height: 15vmin;
    transition: 0.5s !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.social-button:hover {
  transform: scale(1.1);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */